import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function sliderVideo () {
  const slider = document.getElementById('jsSliderVideo'),
        options = {
          container: '#jsSliderVideo',
          mode: 'carousel',
          items: 1,
          controls: false,
          nav: true,
          navPosition: 'bottom'
        };

  if (slider) {
    const sliderVideo = tns(options);
    return sliderVideo;
  }
}

sliderVideo();
