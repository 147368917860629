import { tns } from '../../../../node_modules/tiny-slider/src/tiny-slider.js';

function highlightSlider () {
  const slider = document.getElementById('jsHighlightSlider'),
        options = {
          container: '#jsHighlightSlider',
          mode: 'carousel',
          items: 1,
          controls: true,
          controlsPosition: 'bottom',
          nav: false,
          responsive: {
            576: {
              items: 2
            },
            992: {
              items: 3
            }
          },
          speed: 400,
          gutter: 96
        };
  if (slider) {
    const highlightSlider = tns(options);
    return highlightSlider;
  }
}

highlightSlider();
